import './App.css';
import {useEffect, useState} from "react";
import NavBar from "./NavBar";
import Main from "./Main";

function App() {
    let VK = window.VK

    let [userId, setUserId] = useState(0)
    let [userName, setUserName] = useState('')

    let [implicitFlow, setImplicitFlow] = useState({
        accessToken: '',
        expiresIn: '',
        userId: ''
    })

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        const expiresIn = localStorage.getItem('expires_in')
        const userId = localStorage.getItem('user_id')

        setImplicitFlow({
            accessToken: accessToken === null ? '' : accessToken,
            expiresIn: expiresIn === null ? '' : expiresIn,
            userId: userId === null ? '' : userId
        })

        VK.Auth.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                console.log(response)
                updateLoggedUserInfo(response.session.mid)
            }
        })
    }, [])

    function clearIfTokenClick(e) {
        e.preventDefault()

        localStorage.clear()

        setImplicitFlow({
            accessToken: '',
            expiresIn: '',
            userId: ''
        })
    }

    function updateLoggedUserInfo(userId) {
        setUserId(userId)

        VK.Api.call('users.get',
            {
                user_ids: userId,
                fields: 'screen_name',
                v: '5.131'
            },
            function (response) {
                const user = response.response[0]
                setUserName(user.first_name + ' ' + user.last_name)
            })
    }

    function logOut() {
        setUserId(0)
        setUserName('')
    }

    return (
        <>
            <NavBar userName={userName} implicitFlow={implicitFlow} clearIfTokenClick={clearIfTokenClick}
                    logOut={logOut}/>
            <Main userId={userId} userName={userName} updateLoggedUserInfo={updateLoggedUserInfo}
                  implicitFlow={implicitFlow}/>
        </>
    );
}

export default App;
