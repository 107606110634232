import React from 'react';

const ProgressBar = function (props) {
    return (
        <div className="progress" style={{height: "50px"}}>
            <div
                className="progress-bar progress-bar-striped"
                role="progressbar"
                aria-label="Default striped example"
                style={{width: props.percent + "%"}}
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
    )
}

export default ProgressBar