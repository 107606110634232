import React, {useState} from 'react';

const ImplicitFlow = () => {
    let [implicitTokenUrl, setImplicitTokenUrl] = useState('')

    function saveTokenClick(e) {
        e.preventDefault()

        const params = {}
        try {
            const urlParts = implicitTokenUrl.split('#')
            const response = urlParts[1]
            const parts = response.split('&')

            for (const part of parts) {
                let paramParts = part.split('=')
                params[paramParts[0]] = paramParts[1]
            }
        } catch (e) {
            console.log(e)
            alert('Не удалось распознать токен')
            return
        }

        console.log(params)

        localStorage.setItem('access_token', params.access_token)
        localStorage.setItem('expires_in', params.expires_in)
        localStorage.setItem('user_id', params.user_id)

        window.location = '/'
    }

    return (
        <div className="container text-center">
            <h1 className="text-center">Implicit Flow</h1>
            <div className="row">
                <p className="mb-0">Перейдите по ссылке и вставьте значение из адресной строки после перехода:</p>
                <a href="https://oauth.vk.com/authorize?client_id=51463089&redirect_uri=https://oauth.vk.com/blank.html&response_type=token&scope=wall%2Coffline">Перейти</a>
            </div>

            <div className="d-flex flex-row mt-3">
                <input className="form-control" type="text" value={implicitTokenUrl}
                       onChange={e => setImplicitTokenUrl(e.target.value)}/>
                &nbsp;
                <button className="btn btn-primary" onClick={saveTokenClick}>Сохранить</button>
            </div>
        </div>
    );
};

export default ImplicitFlow;