import React, {useState} from 'react';
import PagesSelect from "./PagesSelect";
import SuggestionsTable from "./SuggestionsTable";
import moment from "moment/moment";
import ProgressBar from "./ProgressBar";

const Main = (props) => {
    const VK = window.VK

    let [pageId, setPageId] = useState(0)
    let [suggestions, setSuggestions] = useState([])
    let [postStartDateTime, setPostStartDateTime] = useState(moment().format('DD.MM.YYYY HH:mm'))
    let [postingInProgress, setPostingInProgress] = useState(false)
    let [deletingInProgress, setDeletingInProgress] = useState(false)

    let [currentPostPercent, setCurrentPostPercent] = useState(0)

    function pageIdChanged(newPageId) {
        setPageId(newPageId)
        updateSuggestions(newPageId)
    }

    function updateSuggestions(pageId) {
        if (pageId === 0)
            return

        VK.Api.call('wall.get', {
                owner_id: -1 * pageId,
                filter: 'suggests',
                count: 100,
                v: '5.131'
            },
            function (response) {
                response = response.response
                let suggestions = response.items

                console.log(suggestions)

                suggestions.sort((a, b) => a.date - b.date)

                let localSuggestions = []
                for (let i = 0; i < suggestions.length; i++) {
                    const suggestion = suggestions[i]
                    const attachments = []

                    const createdOn = suggestion.date
                    let photo = [];

                    if (suggestion.attachments) {
                        if (suggestion.attachments.length > 0) {

                            for (let j = 0; j < suggestion.attachments.length; j++) {
                                const attachment = suggestion.attachments[j]
                                const instance = attachment[attachment.type]
                                const attachmentString = attachment.type + instance.owner_id + '_' + instance.id
                                attachments.push(attachmentString)
                            }

                            if (suggestion.attachments[0].type === 'photo') {
                                photo[0] = suggestion.attachments[0].photo.sizes.filter(s => s.type === 'm')[0].url
                                photo[1] = suggestion.attachments[0].photo.sizes
                                    .filter(s => s.type === 'w'
                                        || s.type === 'z'
                                        || s.type === 'y'
                                    )[0].url
                            }
                        }
                    }

                    localSuggestions.push({
                        id: suggestion.id,
                        createdOn: createdOn,
                        photo: photo,
                        message: suggestion.text,
                        attachments: attachments,
                        isAnonymous: suggestion.text && suggestion.text.toLowerCase().includes('анон'),
                        isChecked: false
                    })
                }
                setSuggestions(localSuggestions)
            })
    }

    function loginClick(e) {
        e.preventDefault()

        VK.Auth.login(
            function (response) {
                if (response.session) {
                    props.updateLoggedUserInfo(response.session.mid)
                }
            }, 8192)
    }

    function selectTenPostsClick(e) {
        e.preventDefault()

        const newSuggestions = suggestions

        for (let i = 0; i < newSuggestions.length && i < 10; i++) {
            const newSuggestion = newSuggestions[i]
            newSuggestion.isChecked = true
        }

        setSuggestions([...newSuggestions])
    }

    function clearAllSelectionClick(e) {
        e.preventDefault()

        const newSuggestions = suggestions

        for (let i = 0; i < newSuggestions.length; i++) {
            const newSuggestion = newSuggestions[i]
            newSuggestion.isChecked = false
        }

        setSuggestions([...newSuggestions])
    }

    async function removeSelectedClick(e) {
        e.preventDefault()

        if (props.implicitFlow.accessToken === '') {
            alert('Сначала необходимо получить IF-токен!')
            return
        }

        const answer = window.confirm('Вы точно хотите удалять выбранные сообщения?')
        if (!answer)
            return

        setDeletingInProgress(true)

        try {
            const checkedSuggestions = suggestions.filter(s => s.isChecked)

            for (let i = 0; i < checkedSuggestions.length; i++) {
                const s = checkedSuggestions[i]

                const jsonBody = {
                    accessToken: props.implicitFlow.accessToken,
                    pageId: -1 * pageId,
                    postId: s.id,
                }

                const response = await fetch('https://vkpost.torteg.su/api/wall/delete', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(jsonBody)
                })

                const json = await response.json()
                console.log(json)
            }
        } finally {
            setDeletingInProgress(false)
            updateSuggestions(pageId)
        }
    }

    function handleIsAnonymousChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const newSuggestions = suggestions

        for (let i = 0; i < newSuggestions.length; i++) {
            const newSuggestion = newSuggestions[i]
            if (newSuggestion.id == name) {
                newSuggestion.isAnonymous = value
                break
            }
        }
        setSuggestions([...newSuggestions])
    }

    function handleIsCheckedChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const newSuggestions = suggestions

        for (let i = 0; i < newSuggestions.length; i++) {
            const newSuggestion = newSuggestions[i]
            if (newSuggestion.id == name) {
                newSuggestion.isChecked = value
                break
            }
        }
        setSuggestions([...newSuggestions])
    }

    async function postSelectedSuggestions(e) {
        e.preventDefault()

        if (props.implicitFlow.accessToken === '') {
            alert('Сначала необходимо получить IF-токен!')
            return
        }

        const targetDate = moment(postStartDateTime, 'DD.MM.YYYY HH:mm', true)
        if (!targetDate.isValid()) {
            alert('Неверный формат даты-времени. Верный формат: DD.MM.YYYY HH:mm');
            return
        }

        const nowUnix = moment().unix()
        const postStartUnix = targetDate.unix()
        if (postStartUnix < nowUnix) {
            alert('Время должно быть больше текущего!')
            return
        }

        setPostingInProgress(true)

        try {
            const checkedSuggestions = suggestions.filter(s => s.isChecked)
            const postCount = checkedSuggestions.length
            let publishDate = postStartUnix

            const percentStep = 100 / postCount

            for (let i = 0; i < postCount; i++) {
                const s = checkedSuggestions[i]

                const jsonBody = {
                    accessToken: props.implicitFlow.accessToken,
                    pageId: -1 * pageId,
                    postId: s.id,
                    publishDate: publishDate,
                    signed: !s.isAnonymous,
                    message: s.message,
                    attachments: s.attachments.length === 0 ? null : s.attachments.join(),
                }

                const response = await fetch('https://vkpost.torteg.su/api/wall/post', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(jsonBody)
                })

                const json = await response.json()
                console.log(json)

                publishDate = moment.unix(publishDate).add(1, "hour").unix()

                setCurrentPostPercent( prev => prev + percentStep)

                await sleep(500)
            }
        } finally {
            setPostingInProgress(false)
            setCurrentPostPercent(0)
            updateSuggestions(pageId)
        }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    return (
        <main className="container">
            <div className="bg-light p-5 rounded">
                {props.userId === 0 ?
                    <>
                        <h1>Необходима авторизация</h1>
                        <button className="btn btn-primary" onClick={loginClick}>Войти</button>
                    </>
                    :
                    <>
                        <PagesSelect userId={props.userId} pageIdChanged={pageIdChanged.bind(this)}/>
                        <hr/>
                        <button type="button" className="btn btn-primary" onClick={selectTenPostsClick}>
                            Выделить 10 сообщений
                        </button>
                        &nbsp;
                        <button type="button" className="btn btn-primary" onClick={clearAllSelectionClick}>
                            Очистить выделение
                        </button>
                        &nbsp;
                        <input type="text" className="form-control w-auto d-inline"
                               value={postStartDateTime}
                               onChange={e => setPostStartDateTime(e.target.value)}
                        />
                        &nbsp;
                        {deletingInProgress ?
                            <button className="btn btn-danger" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                Удаление...
                            </button>
                            :
                            <button type="button" className="btn btn-danger" onClick={removeSelectedClick}>
                                Удалить выбранные
                            </button>
                        }
                        &nbsp;
                        {postingInProgress ?
                            <button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                Загрузка...
                            </button>
                            :
                            <button type="button" className="btn btn-primary"
                                    onClick={postSelectedSuggestions.bind(this)}>
                                Опубликовать выбранные
                            </button>
                        }

                        {postingInProgress &&
                            <div>
                                <hr/>
                                <ProgressBar percent={currentPostPercent}/>
                            </div>
                        }

                        <hr/>
                        <SuggestionsTable suggestions={suggestions}
                                          handleIsCheckedChange={handleIsCheckedChange}
                                          handleIsAnonymousChange={handleIsAnonymousChange}/>
                    </>
                }

            </div>

        </main>
    );
};

export default Main;