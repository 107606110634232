import React from 'react'
import Moment from 'react-moment'

const SuggestionsTable = (props) => {

    return (
        <table className="table table-striped">
            <thead>
            <tr>
                <th>Создано</th>
                <th>Фото</th>
                <th>Сообщение</th>
                <th>Анонимно</th>
                <th>Выбрано</th>
            </tr>
            </thead>
            <tbody>
            {props.suggestions.map(s =>
                <tr key={s.id}>
                    <td>
                        <Moment unix format='DD.MM.YYYY HH:mm'>{s.createdOn}</Moment>
                    </td>
                    <td>
                        <a href={s.photo[1]}>
                            <img src={s.photo[0]} alt=""/>
                        </a>
                    </td>
                    <td>{s.message}</td>
                    <td>
                        <input className="form-check-input" type="checkbox" checked={s.isAnonymous}
                               name={s.id}
                               onChange={props.handleIsAnonymousChange}/>
                    </td>
                    <td>
                        <input className="form-check-input" type="checkbox" checked={s.isChecked}
                               name={s.id}
                               onChange={props.handleIsCheckedChange}/>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    );
};

export default SuggestionsTable;