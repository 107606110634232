import React, {useEffect, useState} from 'react';

const PagesSelect = (props) => {
    let VK = window.VK

    let [pageId, setPageId] = useState(0)
    let [pages, setPages] = useState([])

    const handleChange = e => {
        console.log(e.target.value)
        setPageId(e.target.value)
        props.pageIdChanged(e.target.value)
    };

    useEffect(() => {
        VK.Api.call('groups.get', {
                user_id: props.userId,
                filter: 'admin',
                extended: 1,
                count: 100,
                v: '5.131'
            },
            function (response) {
                response = response.response

                if (response.count > 0) {
                    const localPages = []

                    for (let i = 0; i < response.count; i++) {
                        const item = response.items[i]

                        if (item.type === 'page') {
                            localPages.push({id: item.id, name: item.name})
                        }
                    }

                    setPages(localPages)
                    setPageId(localPages[0].id)
                    props.pageIdChanged(localPages[0].id)
                }
            })
    }, [])

    return (
        <select className="form-select" value={pageId} onChange={handleChange} name="pages" id="pages">
            {pages.map(p =>
                <option key={p.id} value={p.id}>{p.name}</option>
            )}
        </select>
    );
};

export default PagesSelect;