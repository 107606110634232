import React from 'react';

const NavBar = (props) => {
    const VK = window.VK

    function signOutClick() {
        VK.Auth.logout(function (response) {
            console.log(response)

            props.logOut()
        })
    }

    return (
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
            <div className="container">
                <a href="/" className="navbar-brand">VkPost</a>

                {props.implicitFlow.accessToken === '' ?
                    <a className="btn btn-warning" href="/implicit-flow">Получить IF-токен</a>
                    :
                    <div>
                        <button className="btn btn-outline-success" disabled>IF-токен ОК</button>
                        &nbsp;
                        <button className="btn btn-outline-danger" onClick={props.clearIfTokenClick}>Очистить IF-токен</button>
                    </div>
                }

                {
                    props.userName !== '' &&
                    <span className="navbar-text">Вы вошли как {props.userName}
                        &nbsp;
                        <button className="btn btn-sm btn-outline-light" onClick={signOutClick}>Выйти</button>
                    </span>
                }

            </div>
        </nav>
    );
};

export default NavBar;